import request from '@/utils/request'

//修改虚拟仿真实验报告
export function editvrstudyreport(data) {
    return request({
        url: '/portal/vr-study-report/edit',
        method: 'POST',
        data
    })
}

//我的虚拟仿真实验报告
export function getvrstudyreportlist(params) {
    return request({
        url: '/portal/vr-study-report/get-my-list',
        method: 'GET',
        params
    })
}

//新增虚拟仿真实验报告
export function addvrstudyreport(data) {
    return request({
        url: '/portal/vr-study-report/add',
        method: 'POST',
        data
    })
}

//获取虚拟仿真实验报告详情
export function getvrstudyreportinfo(params) {
    return request({
        url: '/portal/vr-study-report/get-info',
        method: 'GET',
        params
    })
}
